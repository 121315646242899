.slide {
    background-color: #1a1a1a;
    width: 100%;
    height: 100%;
}

.swiper-container {
    width: 100%;
    height: 100%;
}

#tsparticles {
    width: 100%;
    height: 80%;

}


.homeCard {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: auto;
    height: auto;
    position: absolute;
    top: 5.5vh;

    left: 0px;
    right: 0px;
    margin: auto;
    z-index: 10000
}

.homeCard > h1 {
    font-family: "Josefin Sans";
    font-weight: 200;
    color: #fff;
    font-size: 7vh;
}

.homeCard h3 {
    font-family: "Source Code Pro";
    font-weight: 500;
    color: #fff;
    margin-left: 10vw;
    margin-right: 10vw;
    text-align: center;
}

.homeCard h4 {
    font-family: "Source Code Pro";
    font-weight: 500;
    color: #fff;
    margin-right: 2vh;
    text-align: center;
}

.santino {
    width: auto;
    height: 15vh;
    border-radius: 50%;
    border: 0.4vh solid #fff;
    margin: auto;
}

.imageContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rightCol{
    overflow: hidden;
    font-family: "Josefin Sans";
    opacity: 0;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: 50%;
    background-color: rgba(255,255,255,0.85);
    transition: opacity .4s ease-in-out;
    -moz-transition: opacity .4s ease-in-out;
    -webkit-transition: opacity .4s ease-in-out;
    display: flex;
    align-items: center;
}

.rightCol a {
    color: black;
}

.rightCol a:visited{
    color: black;
}

.rightColContent{
    position: absolute;
    left: -100000px;
    right: 40px;
    transition: 1s;
    top: 3vh;
    bottom: 10vh;
    overflow: auto;
}

.rightColContent p{
    font-family: "Source Code Pro";
}

.rightCol h1{
    font-weight: 400;

}
.rightCol p{
    font-weight: 400;

}

.centerImage {
    width: 80vw;
    height: auto;
}

.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    width: 27px;
}

.swiper-pagination-bullet {

    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid white;
    box-shadow: inset 0 0 0 2px black;
}

.swiper-pagination-bullet-active {
    color: #fff;
    background: #ffffff;
}

.swiper-pagination-bullet-active-next {
    color: #fff;
    background: #ffffff;
}

.swiper-pagination-bullet-active-next-next {
    color: #fff;
    background: #ffffff;
}

#slideContacts h1 {
    text-shadow: 0px 1px 1px #828282;
    color: #494949;
    font: 10vh 'Josefin Sans';
    margin-top: 40px;
}
#slideContacts:before {
    content: '';
    display: block;
    position: absolute;
    top: 70vh;
    left: 50%;

    width: 200vw;

    height: 200vw;

    margin-left: -60rem;
    margin-left: -100vw;
    /* z-index: 100000; */
    border-radius: 50%;
    background-color: #101010;
}

.iconCircle{
    font-size: 70px;
    background-color: transparent;
    border-radius: 50%;
    border-color: white;
    border-style: solid;
    border-width: thin;
    display: inline-block;
    margin: 10px;
    width: 15vw;
    height: 15vw;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 130px;
    max-width: 130px;
    max-font-size: 34px;
}

.swiper-pagination-bullet:focus{
    outline: none;
}


@media only screen and (max-width: 1000px) {

    .iconCircle{
        font-size: 7vw;
    }
}


@media only screen and (max-width: 900px) {
    .rightCol{
        width: 70%;
    }
    .iconCircle{
        font-size: 7vw;
    }
}

@media only screen and (max-width: 750px) {
    .rightCol{
        width: 100%;
    }
    .iconCircle{
        font-size: 7vw;
    }
}